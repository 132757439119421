import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            drupalURL
            frontendURL
            mainMenu {
              title
              url
            }
            social {
              accounts {
                type
                accountName
              }
              pages {
                name
                icon
                url
              }
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};
