import React, { useState } from 'react';
import Logo from './Logo.jsx';
import { Link } from 'gatsby';
import CompanySocialIcons from '../Social/CompanySocialIcons';
import { FiMail } from 'react-icons/fi';
import { isEnterOrSpace } from '../../helpers/isEnterOrSpace';
import ZombieInvasion from '../Elements/ZombieInvasion';
import ZombieInvasionButton from '../Elements/ZombieInvasionButton';

const Footer = () => {
  const [ZombieInvasionStart, setZombieInvasionStart] = useState(false);

  const handleToggle = () => {
    setZombieInvasionStart(!ZombieInvasionStart);
  };
  
  return (
    <footer className={`footer-area ${ ZombieInvasionStart ? 'zombie-invasion-start' : 'zombie-invasion-ends'}`}>
      <ZombieInvasion />
      <div className="footer-inner container is-flex is-justify-content-space-between">
        <div className='footer-logo is-flex is-flex-direction-column is-justify-content-center'>
          <Logo />
          <div className="colum is-size-7">© 2021 easytechgreen.</div>
        </div>
        <div className="footer-info is-flex is-flex-direction-column is-align-items-flex-end">
        <div
          onClick={handleToggle}
          onKeyDown={(e) => isEnterOrSpace(e) && setZombieInvasionStart(true)}
          className='colum p-2 zombie-btn-wrapper'
          role='button'
          tabIndex='0'
        >
            <ZombieInvasionButton />
          </div>
          <div className="colum p-2"><CompanySocialIcons /></div>
          <div className="colum p-2"><Link className="text-decoration-pro email"><FiMail />info@easytechgreen.com</Link></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
