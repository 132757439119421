import React, { useState } from 'react';
import { isEnterOrSpace } from '../../helpers/isEnterOrSpace';
import { Link } from 'gatsby';

const ZombieInvasionButton = () => {
  const [ZombieInvasionBtn, setZombieInvasionBtn] = useState(false);

  const handleToggle = () => {
    setZombieInvasionBtn(!ZombieInvasionBtn);
  };

  return (
    <Link 
      onClick={handleToggle}
      onKeyDown={(e) => isEnterOrSpace(e) && setZombieInvasionBtn(true)}
      className={`zombie-btn ${ ZombieInvasionBtn ? 'zombie-btn--defaultxx' : 'zombie-btn--default'}`}
      role='button'
      tabIndex='0'
    >
      <div className="default-btn zombie-btn-layer zombie-btn-default-hover-lights"></div> 
      <div className="default-btn zombie-btn-layer zombie-btn-default-hover"></div> 
      <div className="default-btn zombie-btn-layer zombie-btn-default"></div> 
      <div className="active-btn zombie-btn-layer zombie-btn-active-lights"></div> 
      <div className="active-btn zombie-btn-layer zombie-btn-active-glow"></div> 
      <div className="active-btn zombie-btn-layer zombie-btn-active"></div> 
    </Link>
  );
};

export default ZombieInvasionButton;
