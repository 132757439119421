import React from 'react';
import * as FaIcons from 'react-icons/fa';
import PropTypes from 'prop-types';


const IconComponent = prop => React.createElement(FaIcons[prop.name]);

IconComponent.propTypes = {
  name: PropTypes.string.isRequired
};

export default IconComponent;
