/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { arrayOf, node, string, object } from 'prop-types';
import Footer from '../Navigation/Footer';
import Header from '../Navigation/Header';

const Layout = ({ className, sections, location, children }) => {
  return (
    <div className={className}>
      <Header sections={sections} location={location} />
        {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  sections: arrayOf(string),
  className: string,
  location: object
};

export default Layout;
