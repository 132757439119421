import React from 'react';
import IconComponent from './IconComponent';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

const CompanySocialIcons = () => {
  const { social } = useSiteMetadata();
  return (
    <div className='social-share-wrapper'>
      <ul className='social-share is-flex'>
        {social.pages.map((val, index) => {
          const {icon, url} = val;
          return (
            <li key={index}>
              <a href={`${url}`} target='_blank' rel='noopener noreferrer'><IconComponent name={icon}></IconComponent></a>
            </li>
          );
        })
      }
      </ul>
    </div>
  );
};

export default CompanySocialIcons;
