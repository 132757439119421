import React, { useEffect, useState } from 'react';
import { arrayOf, object, string } from 'prop-types';
// import { ThemeToggler } from 'gatsby-plugin-dark-mode';
// Descomentar cuando este terminado
import Icon from 'react-svg-use';

import HeaderMenu from './HeaderMenu.jsx';
import Logo from './Logo.jsx';
import ElementVisibilitySpy from '../Elements/ElementVisibilitySpy';
import { isEnterOrSpace } from '../../helpers/isEnterOrSpace';

//import LinkOne from '../../images/humberger-menu/linkOne.inline.svg';
// import LinkTwo from '../../images/humberger-menu/linkTwo.inline.svg';
import PathLink from '../../images/humberger-menu/pathLink.inline.svg';



const Header = ({ sections, location }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', function() {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });
  }, []);

  return (
    <>
      <ElementVisibilitySpy idsToSpy={sections} callback={(element) => setCurrentSection(element.target.id)}></ElementVisibilitySpy>
      <header className={`header-area header-style-two header--fixed ${currentSection} ${ menuIsOpen ? 'menu-open' : ''}`}>
        <div className='header-wrapper'>
          <div className='header-logo'>
            <Logo />
          </div>
          <div className='header-left'>
            <HeaderMenu location={location}/>
          </div>
          <div className='header-right'>
            {/* <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <label>
                  <input
                    type="checkbox"
                    onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                    checked={theme === 'dark'}
                  />{' '}
                  Dark mode
                </label>
              )}
            </ThemeToggler> */}
            {/* Start Humberger Menu  */}
            <div className='humberger-menu'>
              <div
                onClick={() => setMenuIsOpen(true)}
                onKeyDown={(e) => isEnterOrSpace(e) && setMenuIsOpen(true)}
                className='humberger-menu-inner'
                role='button'
                tabIndex='0'
              >
                <div className="humberger-bar-wrapper">
                  <div className="humberger-bar"></div>
                  <div className="humberger-bar"></div>
                </div>
                <Icon id='path'/>
                <Icon id='path'/>
              </div>
              {/* End Humberger Menu  */}
                <div
                  onClick={() => setMenuIsOpen(false)}
                  onKeyDown={(e) => isEnterOrSpace(e) && setMenuIsOpen(false)}
                  className='close-humberger'
                  role='button'
                  tabIndex='0'
                >
              </div>
              <PathLink/>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  sections: arrayOf(string),
  location: object
};

Header.defaultProps = {
  sections: []
};

export default Header;
