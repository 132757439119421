import React, { useEffect } from 'react';
import { arrayOf, string, node, func } from 'prop-types';

import useObserver from '../../hooks/use-observer';

const ElementVisibilitySpy = ({ idsToSpy, callback, children }) => {

  const [, setElements, entries] = useObserver({
    root: null
  });

  useEffect(() => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        callback(entry);
      }
    });
  }, [entries, callback]);

  useEffect(() => {
    const elements = idsToSpy
      .map(elementId => document.getElementById(elementId))
      .filter(Boolean);
    console.log(elements);
    setElements(elements);
  }, [idsToSpy, setElements]);

  return (
    <>{children}</>
  );

};

ElementVisibilitySpy.propTypes = {
  idsToSpy: arrayOf(string),
  callback: func,
  children: node
};

export default ElementVisibilitySpy;
