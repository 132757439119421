import React from 'react';
import { Link } from 'gatsby';
import { shape, string } from 'prop-types';

import { useSiteMetadata } from '../../hooks/use-site-metadata';

const HeaderMenu = ({ location }) => {

  const { mainMenu } = useSiteMetadata();

  const isActive = (url) => {
    if (location) {
      return (location.pathname === url) || (url !== '/' && location.pathname.startsWith(url));
    }
  };

  return (
    <nav className="mainmenunav">
      <ul className="mainmenu">
      { mainMenu.map((link, index) => (
        <li key={index} className={isActive(link.url) ? 'is-current' : ''}>
          <Link to={link.url}>{link.title}</Link>
        </li>
      )) }
      <div className="active-animation"></div>
      </ul>
    </nav>
  );
};

HeaderMenu.propTypes = {
  location: shape({
    pathname: string
  })
};

export default HeaderMenu;
