import React from 'react';

const ZombieInvasion = () => {

  return (
    <div className="zombie-invasion">

      {/* zombie */}
      <div className="walk-container">
        <div></div>
      </div> 
      <div className="particle-container-wrapper">
        <div className="particle-container">
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
        </div>
      </div>

      <div className="stone">
        <div></div>
      </div> 
      <div className="stone2">
        <div></div>
      </div> 

      <div className="hand">
        <div></div>
      </div> 

      <div className="coffin">
        <div></div>
      </div> 

      <div className="skeleton">
        <div></div>
      </div> 

      <div className="grid">
        <div></div>
      </div> 

      <div className="ground">
        <div></div>
      </div> 
    </div>
  );
};

export default ZombieInvasion;
