import React from 'react';
import { Link } from 'gatsby';
import SiteLogo from '../../images/logo.inline.svg';
import PolygonsAnimation from '../../images/polygons.inline.svg';

const Logo = () => {
    return (
    <div className="logo-wrapper">
      <Link className="logo-link logo" to={`/`}>
        <SiteLogo alt="Easytech Green Logo" />
        <PolygonsAnimation />
      </Link>
    </div>
  );
};

export default Logo;
